<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <div class="row">
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label>Loại BU</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.buSource">
                <option selected disabled>Chọn BU</option>
                <option v-for="item in listBU" :value="item" :key="item">
                  {{ item }}
                </option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3" class="mb-2">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <div class="row col-md-12 justify-content-end">
          <button
            ref="submit-search"
            class="
              btn btn-elevate btn-primary
              font-weight-bold
              px-11
              py-2
              my-4
              font-size-3
            "
            @click="search()"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div id="detail" class="row">
      <div class="col-md-12">
        <b-card-group deck>
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <h6 class="mb-0">Các chỉ số BU</h6>
            </template>
            <div class="row">
              <div class="col-md-12">
                <div class="card card-custom gutter-b">
                  <div class="card-header h-auto">
                    <div class="card-title py-5">
                      <h3 class="card-label">NRU</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="d-flex flex-column text-right">
                      <span class="text-dark-75 font-weight-bolder font-size-h3"
                        >+ {{ this.nruAdded }}</span
                      >
                      <span class="text-muted font-weight-bold mt-2"
                        >New Users</span
                      >
                    </div>
                    <div id="chartNru"></div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card card-custom gutter-b">
                  <div class="card-header h-auto">
                    <div class="card-title py-5">
                      <h3 class="card-label">NPU</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="d-flex flex-column text-right">
                      <span class="text-dark-75 font-weight-bolder font-size-h3"
                        >+ {{ this.npuAdded }}</span
                      >
                      <span class="text-muted font-weight-bold mt-2"
                        >New Users</span
                      >
                    </div>
                    <div id="chartNpu"></div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import ApexCharts from "apexcharts";
import Message from "@/core/config/message.config";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const ReportRepository = RepositoryFactory.get("report");
// import Message from "@/core/config/message.config";
const primary = "#6993FF";
const success = "#1BC5BD";
// const info = '#8950FC';
// const warning = '#FFA800';
// const danger = '#F64E60';
export default {
  name: "BuFuntap",
  mixins: [Common],
  components: {},
  data() {
    return {
      nruAdded: 0,
      npuAdded: 0,
      nruChart: false,
      npuChart: false,
      searchData: {
        buSource: "",
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay(),
      },
      listBU: ["BU1", "BU2", "BU3", "COPUB"],
    };
  },
  methods: {
    async search() {
      if (!this.searchData.buSource) {
        this.showError(Message.ERROR.BU_SOURCE_REQUIRE);
        return;
      }
      if (
        !this.dateIsValid(this.searchData.startDate) ||
        !this.dateIsValid(this.searchData.endDate)
      ) {
        this.showError(Message.ERROR.DATE_REQUIRED);
        return;
      }

      if (this.searchData.endDate < this.searchData.startDate) {
        this.showError(Message.ERROR.DATE_RANGE_INVALID);
        return;
      }

      const reportData = await this.getBuReport();
      if (!reportData) {
        return;
      }
      this.showChartNru(reportData.nru);
      this.showChartNpu(reportData.npu);
    },

    async getBuReport() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = {
          from_date: this.searchData.startDate,
          to_date: this.searchData.endDate,
          bu_source: this.searchData.buSource,
        };
        const response = await ReportRepository.getBuReport(params);

        let body = response.data;
        this.$bus.$emit("show-loading", false);
        return body.data;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
        return this.showError(Message.ERROR.SERVER);
      }
    },

    showChartNru(nruData) {
      this.nruAdded = 0;
      let initData = this.buildChartInitData(
        this.searchData.startDate,
        this.searchData.endDate
      );
      initData.forEach((data) => {
        nruData.forEach((val) => {
          if (data.x === val.date) {
            data.y = val.total;
            this.nruAdded += val.total;
          }
        });
      });

      const apexChart = "#chartNru";
      const options = {
        series: [{ data: initData }],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        colors: [primary],
      };
      if (this.nruChart) {
        this.nruChart.destroy();
      }
      this.nruChart = new ApexCharts(
        document.querySelector(apexChart),
        options
      );

      this.nruChart.render();
    },

    showChartNpu(npuData) {
      this.npuAdded = 0;
      let initData = this.buildChartInitData(
        this.searchData.startDate,
        this.searchData.endDate
      );

      initData.forEach((data) => {
        npuData.forEach((val) => {
          if (data.x === val.date) {
            data.y = val.total;
            this.npuAdded += val.total;
          }
        });
      });

      const apexChart = "#chartNpu";
      const options = {
        series: [{ data: initData }],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        colors: [success],
      };
      if (this.npuChart) {
        this.npuChart.destroy();
      }
      this.npuChart = new ApexCharts(
        document.querySelector(apexChart),
        options
      );

      this.npuChart.render();
    },
    buildChartInitData(startDate, endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);

      const date = new Date(start.getTime());
      const dates = [];
      while (date <= end) {
        dates.push({ x: new Date(date).toISOString().split("T")[0], y: 0 });
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "BU Funtap" }]);
  },
};
</script>
